import React from "react";
import { Col } from "reactstrap";
import HeaderLogo from "src/components/HeaderLogo";
import { RequestStatus } from "src/lib/api/types";
import { useApi } from "src/lib/ApiProvider";
import { toQueryParms } from "src/utils";

import { ReceiptsForm } from "./ReceiptsForm";
import { ReceiptResult } from "./ReceiptsResult";
import { ReceiptFormValues, ReceiptInfo } from "./types";

export const Receipts = () => {
  const [receipts, setReceipts] = React.useState<ReceiptInfo[]>([]);
  const [requestStatus, setRequestStatus] =
    React.useState<RequestStatus>("idle");
  const { api } = useApi();
  const handleSubmit = React.useCallback(
    async (values: ReceiptFormValues) => {
      setRequestStatus("loading");
      try {
        const response = await api.get(
          `api/receipts?${toQueryParms(
            values as unknown as Record<string, string>
          )}`
        );
        setReceipts(response.data);
        setRequestStatus("success");
      } catch (err) {
        setRequestStatus("error");
      }
    },
    [api]
  );

  return (
    <div style={{ padding: "20px" }}>
      <HeaderLogo />
      <Col>
        <ReceiptsForm onSubmit={handleSubmit}>
          <ReceiptResult receipts={receipts} requestStatus={requestStatus} />
        </ReceiptsForm>
      </Col>
    </div>
  );
};
