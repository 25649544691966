import { StyleSheet } from "react-native";
import { baseStyles } from "src/components/v21/text/BaseText";
import { Flavor } from "src/lib/flavors";
import { dpToPx } from "src/styles";

export const styles = (flavor: Flavor) =>
  StyleSheet.create({
    wrapper: {
      width: "100%",
    },
    title: {
      ...baseStyles(flavor).receiptHeadline,
      paddingTop: dpToPx(24),
    },
    logoWrapper: {
      alignItems: "center",
      padding: dpToPx(24),
    },
  });
