import axios, { AxiosInstance } from "axios";
import React, { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { config } from "./config";

interface AxiosProviderValues {
  authApi: AxiosInstance;
  api: AxiosInstance;
}

export const ApiProviderContext = React.createContext<AxiosProviderValues>(
  null as any
);

export const ApiProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { sessionToken } = useParams();

  const api = axios.create({
    baseURL: config.OASIS_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const authApi = axios.create({
    baseURL: config.OASIS_API_URL,
    headers: {
      "Content-Type": "application/json",
      authorization: sessionToken ? `Bearer ${sessionToken}` : "",
    },
  });

  return React.createElement(
    ApiProviderContext.Provider,
    {
      value: {
        api,
        authApi,
      },
    },
    children
  );
};

export const useApi = () => React.useContext(ApiProviderContext);
