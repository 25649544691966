import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoREWE } from "src/components/LogoREWE";

class REWEFlavor extends Flavor {
  getName() {
    return "REWE";
  }

  getFullName() {
    return "REWE";
  }

  getMainColor() {
    return color2.BOXLL_RED;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.BOXLL_RED];
  }

  getDashLineColor() {
    return color2.BOXLL_RED;
  }

  getTelephone() {
    return "";
  }

  getSupportEmail() {
    return "servicebox@lekkerland.com";
  }

  getAddress() :string {
    return "REWE TO GO\nLekkerland SE\nEuropaallee 57\n50226 Frechen";
  }

  getTouLink() {
    return "http://aifi.com";
  }

  getAboutUsLink() {
    return "http://aifi.com";
  }

  getAPIEndpoint() {
    return "https://oasis-api.58-38.client.aifi.io";
  }

  getStripePublicKey() {
    return "";
  }

  getCircleLogo() {
    return <LogoREWE />;
  }
}

const Singleton = new REWEFlavor();
export default Singleton;
