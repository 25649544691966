import { useState } from "react";
import { setShopData } from "../api/storage";
import { Flavor } from "../flavors";
import { getFlavor } from "../flavors/utils";
import { IMasterContext } from "../masterContext";

export const useMasterContext = (flavorName: string): IMasterContext => {
  const flavClass = getFlavor(flavorName);
  const [flavor, setFlavor] = useState<Flavor>(flavClass);
  const featureFlags = flavClass.getFeatureFlags();

  const setStoreData = (storeData: StoreData) =>
    setShopData(JSON.stringify(storeData));

  return {
    setStoreData,
    flavor,
    setFlavor,
    featureFlags,
  };
};
