import { isEmpty } from "ramda";
import envVars from "./env";

export { envVars };

export const flavorName = envVars.REACT_APP_NAME;

export const config = {
  OASIS_API_URL: process.env.REACT_APP_HOST_URL || window.location.origin,
  messages: {
    contestConfirmation: isEmpty(process.env.REACT_APP_MSG_CONTEST_CONFIRMATION)
      ? "Your receipt has been submitted with success and we will get back to you shortly. We will not charge anything on the top of the original receipt amount. Thank you for helping us to improve!"
      : process.env.REACT_APP_MSG_CONTEST_CONFIRMATION,
    footerPaid: process.env.REACT_APP_MSG_FOOTER_PAID || "",
    footerInReview: isEmpty(process.env.REACT_APP_MSG_FOOTER_IN_REVIEW)
      ? `Your receipt change request is being reviewed.  Please allow up to 48 hours for review. Once the review has been completed, the final receipt will be displayed above with payment status changed from ‘in review’ to ‘revised.’`
      : process.env.REACT_APP_MSG_FOOTER_IN_REVIEW,
    footerReviewed: isEmpty(process.env.REACT_APP_MSG_FOOTER_REVIEWED)
      ? `Your receipt change request  has been reviewed. Please find your final receipt above.`
      : process.env.REACT_APP_MSG_FOOTER_REVIEWED,
  },
};
