import React, { useContext } from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { styles } from "./styles";
import { Button } from "../../../../components/v2/Button";
import { translate } from "../../../../lib/i18n";
import { MasterContext } from "src/lib/masterContext";

interface IReceiptFooter {
  showSubmit: boolean;
  onSubmit: () => void;
  submitText?: string;
  loading?: boolean;
  orderId?: string;
  footerText?: string;
  isSubmitDisabled?: boolean;
}

const ReceiptFooter = ({
  onSubmit,
  showSubmit,
  submitText,
  loading = false,
  orderId,
  footerText,
  isSubmitDisabled,
}: IReceiptFooter) => {
  const { flavor } = useContext(MasterContext);

  const title = submitText || translate("receiptDetailComplain.reportProblem");

  return (
    <>
      <View style={styles.addressWrapper}>
        {orderId && (
          <Text style={styles.address}>
            {`${translate("receiptDetailScreen.orderId")}\n`}
            {orderId}
          </Text>
        )}
        <Text style={styles.star}>* * *</Text>
        <Text style={styles.address}>{footerText ?? ""}</Text>
        <Text style={styles.address}></Text>
      </View>
      {showSubmit &&
        (loading ? (
          <ActivityIndicator color={flavor.getSpinnerColor()} />
        ) : (
          <Button
            disabled={isSubmitDisabled}
            style={styles.submitButton}
            title={title}
            onPress={onSubmit}
          />
        ))}
    </>
  );
};

export default ReceiptFooter;
