import React from "react";
import { Row, Spinner } from "reactstrap";
import { RequestStatus } from "src/lib/api/types";
import { MasterContext } from "src/lib/masterContext";
import { ReceiptsList } from "./ReceiptsList";
import { ReceiptInfo } from "./types";

export interface ReceiptsResultProps {
  receipts?: ReceiptInfo[];
  requestStatus: RequestStatus;
}

export const ReceiptResult: React.FC<ReceiptsResultProps> = ({
  requestStatus,
  receipts,
}) => {
  const { flavor } = React.useContext(MasterContext);
  const email = flavor.getSupportEmail();
  if (requestStatus === "idle") {
    return <></>;
  } else if (requestStatus === "loading") {
    return (
      <Row className="justify-content-center">
        <Spinner />
      </Row>
    );
  } else if (requestStatus === "error") {
    return (
      <span>
        Something went wrong we couldn't find your receipts. Please contact with
        our support <a href={`mailto:${email}`}>{email}</a>
      </span>
    );
  } else if (requestStatus === "success" && !receipts?.length) {
    return <span>There are no receipts for provided card details</span>;
  } else if (requestStatus === "success" && receipts && receipts?.length) {
    return <ReceiptsList receipts={receipts} />;
  }

  return <></>;
};
