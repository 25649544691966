import { useFormik } from "formik";
import React, { PropsWithChildren } from "react";
import InputMask from "react-input-mask";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { translate } from "src/lib/i18n";
import styled from "styled-components";
import { ReceiptFormValues } from "./types";

import { loginPageSchema } from "./utils";

export const ErrorMessage = styled.div`
  color: #f02849;
  text-align: left;
`;

const Instruction = styled.a`
  text-align: left;
  display: block;
  margin-top: 8px;
`;

interface ReceiptsFormProps {
  onSubmit: (values: ReceiptFormValues) => void;
}

export const ReceiptsForm: React.FC<PropsWithChildren<ReceiptsFormProps>> = ({
  onSubmit,
  children,
}) => {
  const receiptsForm = useFormik({
    initialValues: {
      customerPaymentInstrumentLast4: "",
      customerPaymentInstrumentExpDate: "",
      visitDate: "",
      cardType: "card",
    },
    validationSchema: loginPageSchema,
    onSubmit,
  });

  return (
    <div style={{ fontWeight: 500 }}>
      <Form
        className="row justify-content-center"
        onSubmit={receiptsForm.handleSubmit}
      >
        <FormGroup row className="justify-content-center">
          <Label for="cardType" sm={2}>
            {translate("receiptsHome.cardType")}
          </Label>
          <Col sm={3}>
            <ButtonGroup style={{ width: "100%" }}>
              <Button
                outline={receiptsForm.values.cardType !== "card"}
                onClick={() => {
                  receiptsForm.setFieldValue("cardType", "card");
                }}
              >
                {translate("receiptsHome.card")}
              </Button>
              <Button
                outline={receiptsForm.values.cardType !== "apple"}
                onClick={() => {
                  receiptsForm.setFieldValue("cardType", "apple");
                }}
              >
                Apple Pay
              </Button>
              <Button
                outline={receiptsForm.values.cardType !== "google"}
                onClick={() => {
                  receiptsForm.setFieldValue("cardType", "google");
                }}
              >
                Google Pay
              </Button>
            </ButtonGroup>
            {receiptsForm.values.cardType !== "card" ? (
              <Instruction
                target="_blank"
                href={
                  receiptsForm.values.cardType === "apple"
                    ? "/card-instruction-apple"
                    : "https://support.google.com/wallet/answer/12059601?hl=en&ref_topic=11925503"
                }
                rel="noreferrer"
              >
                {translate("receiptsHome.howToFind")}
              </Instruction>
            ) : (
              ""
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="justify-content-center">
          <Label for="input-cc-last-digits" sm={2}>
            {translate("receiptsHome.last4")}
          </Label>
          <Col sm={3}>
            <Input
              id="input-cc-last-digits"
              name="customerPaymentInstrumentLast4"
              placeholder="e.g. 1234"
              value={receiptsForm.values.customerPaymentInstrumentLast4}
              onChange={(e) => {
                const value = e.currentTarget.value;
                if (value.length < 5) {
                  receiptsForm.handleChange(e);
                }
              }}
              onBlur={receiptsForm.handleBlur}
              maxLength={4}
            />
            {receiptsForm.errors.customerPaymentInstrumentLast4 &&
            receiptsForm.touched.customerPaymentInstrumentLast4 ? (
              <ErrorMessage>
                {receiptsForm.errors.customerPaymentInstrumentLast4}
              </ErrorMessage>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row className="justify-content-center">
          <Label for="input-exp-date" sm={2}>
            {translate("receiptsHome.expDate")}
          </Label>
          <Col sm={3}>
            <Input
              id="input-exp-date"
              name="customerPaymentInstrumentExpDate"
              placeholder="MM/YY"
              value={receiptsForm.values.customerPaymentInstrumentExpDate}
              onChange={receiptsForm.handleChange}
              onBlur={receiptsForm.handleBlur}
              mask="99/99"
              tag={InputMask}
            />
            {receiptsForm.errors.customerPaymentInstrumentExpDate &&
            receiptsForm.touched.customerPaymentInstrumentExpDate ? (
              <ErrorMessage>
                {receiptsForm.errors.customerPaymentInstrumentExpDate}
              </ErrorMessage>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row className="justify-content-center">
          <Label for="input-visit-date" sm={2}>
            {translate("receiptsHome.visitDate")}
          </Label>
          <Col sm={3}>
            <Input
              id="input-visit-date"
              name="visitDate"
              type="date"
              value={receiptsForm.values.visitDate}
              onChange={receiptsForm.handleChange}
              onBlur={receiptsForm.handleBlur}
            />
            {receiptsForm.errors.visitDate && receiptsForm.touched.visitDate ? (
              <ErrorMessage>{receiptsForm.errors.visitDate}</ErrorMessage>
            ) : null}
          </Col>
        </FormGroup>
        <Row className="justify-content-center">
          <Col sm={2} md={5}>
            {children}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={3} md={1} style={{ padding: "20px 0" }}>
            <Row>
              <Button
                type="submit"
                disabled={!receiptsForm.isValid}
                title={translate("receiptsHome.submit")}
              >
                {translate("receiptsHome.submit")}
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
