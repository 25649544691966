import React from "react";
import { View, Text } from "react-native";
import { DashLine } from "../../../../components/v2/Dashline";
import ReceiptDetailSection from "../../../../components/v2/ReceiptsDetailSection";
import { translate } from "../../../../lib/i18n";
import { styles } from "./styles";
import { styles as parentStyles } from "../styles";
import { useFlavor as withFlavor } from "../../../../lib/hooks/useFlavor";
import { LogoCircle } from "src/components/LogoCircle";
import { Flavor } from "src/lib/flavors";

type IReceiptHeader = {
  value?: string;
  rightView?: React.ReactNode;
  flavor: Flavor;
};

export const ReceiptHeader = ({ value, rightView, flavor }: IReceiptHeader) => {
  const css = styles(flavor);
  return (
    <View style={css.wrapper}>
      <View style={css.logoWrapper}>
        <LogoCircle />
        <Text style={parentStyles.title}>
          {translate("receiptDetailScreen.headerTitle") as string}
        </Text>
      </View>
      <DashLine />
      <ReceiptDetailSection
        name="status"
        data={[
          {
            title: translate("receiptDetailScreen.paymentStatus") as string,
            value,
            rightView,
          },
        ]}
      />
    </View>
  );
};

export default withFlavor(ReceiptHeader);
