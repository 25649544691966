import { config } from "../config";
import { getAuthToken } from "./storage";
import {
  ContestReceiptData,
  ContestReceiptResponse,
  ProductData,
} from "./types";

const apiUrl = (path: string) =>
  `${config.OASIS_API_URL}/api/customer/v2${path}`;

const buildHeaders = async () => {
  const accessToken = await getAuthToken();
  return {
    "content-type": "application/json",
    authorization: `Bearer ${accessToken}`,
  };
};

const post = async (url: string, body: { [key: string]: any }) => {
  const headers = await buildHeaders();
  const req = new Request(apiUrl(url), {
    method: "POST",
    headers: {
      ...headers,
    },
    body: JSON.stringify(body),
  });
  return await fetch(req).then((response) => response.json());
};

const get = async (url: string) => {
  const headers = await buildHeaders();
  const req = new Request(apiUrl(url), {
    method: "GET",
    headers: {
      ...headers,
    },
  });
  return await fetch(req)
    .then(async (response) => {
      return { ok: response.ok, data: await response.json() };
    })
    .then(({ ok, data }) => {
      if (ok) {
        return data;
      }
      throw new Error(data.message);
    });
};

const getOrder = async (orderId: Number) => {
  return await get(`/orders/${orderId}`);
};

function sendContestedReceipt(data: ContestReceiptData) {
  return post(
    "/orders/contest-receipt",
    data
  ) as Promise<ContestReceiptResponse>;
}

const handleError = console.error;

const getProducts = (data: ProductData) => {
  return get(`/products?name=${data.name}`) as Promise<ApiProductsDetail[]>;
};

const api = { getOrder, sendContestedReceipt, handleError, getProducts };

export default api;
