import React from "react";
import { Text, View } from "react-native";
import { LogoCircle } from "src/components/LogoCircle";
import { Flavor } from "src/lib/flavors";

import { useFlavor as withFlavor } from "../../lib/hooks/useFlavor";
import { styles } from "./styles";

type IReceiptHeader = {
  flavor: Flavor;
  text?: string;
};

const HeaderLogo = ({ flavor, text }: IReceiptHeader) => {
  const css = styles(flavor);
  return (
    <View style={css.wrapper}>
      <View style={css.logoWrapper}>
        <LogoCircle />
        <Text>{text}</Text>
      </View>
    </View>
  );
};

export default withFlavor(HeaderLogo);
