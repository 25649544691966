import React from "react";
import { View, Text, ViewProps, TextProps, TextStyle, FlexStyle } from "react-native";

import { styles } from "./style";

interface IBothSideTextView extends ViewProps {
  title: string;
  detailTitle?: string | null;
  titleStyle?: TextProps;
  detailTitleStyle?: TextProps & TextStyle;
  rightView?: React.ReactNode;
  leftView?: React.ReactNode;
  middleView?: React.ReactNode;
  leftViewStyle?: FlexStyle;
}

export const BothSideTextView: React.FC<IBothSideTextView> = ({
  title,
  detailTitle,
  titleStyle,
  detailTitleStyle,
  rightView,
  leftView,
  middleView,
  leftViewStyle,
  ...props
}) => {
  const wrapper = [styles.wrapper, props.style];
  const leftStyle = [styles.text, styles.leftText, titleStyle, leftViewStyle || {}];
  const rightStyle = [styles.text, styles.rightText, detailTitleStyle];
  return (
    <View style={wrapper}>
      {leftView || <Text style={leftStyle}>{title}</Text>}
      {middleView && middleView}
      {rightView && rightView}
      {detailTitle && <Text style={rightStyle}>{detailTitle}</Text>}
    </View>
  );
};
