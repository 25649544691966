import React from "react";
import { NavigationParams, NavigationScreenProp } from "react-navigation";
import * as R from "ramda";
import dayjs from "dayjs";
import { Flavor } from "./flavor";
import { defaultFeatureFlags } from "./flavorTypes";
import { color2 } from "src/styles";
import { translate } from "src/lib/i18n";
// import { OnboardingZabka } from "src/views/Onboarding/zabka";
import { LogoZabka } from "src/components/LogoZabka";
import { currencyMap } from "../constants";

const polishTaxIdValidation = (nip: string) => {
  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  nip = nip.replace(/[\s-]/gu, "");

  if (nip.length !== 10 || parseInt(nip, 10) <= 0) {
    return false;
  }
  const nipDigits = [...nip].map(Number);
  const sum = R.sum(R.zipWith((x, y) => x * y, nipDigits, weights));

  return sum % 11 === nipDigits[9];
};

class ZabkaFlavor extends Flavor {
  getCode() {
    return "22-5";
  }

  getName() {
    return "Żabka ON";
  }

  getFullName() {
    return "Żabka Polska sp. z o.o.";
  }

  getMainColor() {
    return color2.ZABKA;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.ZABKA];
  }

  taxValidation(value: string) {
    return polishTaxIdValidation(value);
  }

  getFaqTranslation = () => translate("settingsScreen.items.faq2");

  getFaqAction(navigation: NavigationScreenProp<NavigationParams>) {
    navigation.navigate("Onboarding");
  }

  getGDPRText() {
    return "Administratorem Pani/Pana danych osobowych jest Żabka Polska sp. z o.o. z siedzibą w Poznaniu (61-586) przy ul. Stanisława Matyi 8. Pani/Pana dane będą przetwarzane w celu założenia i obsługi konta w Aplikacji oraz zapewnienia możliwości korzystania z rozwiązań Sklepu Bezobsługowego i w celach z tym związanych, a w przypadku wyrażenia stosownej zgody – w celu wyświetlania komunikatów push o aplikacji i zakupach w Sklepach Bezobsługowych. Administrator korzysta z usług dostawcy technologii Sklepu Bezobsługowego oraz dostawcy rozwiązań e-commerce, mających siedzibę w USA. Administrator zastosował dodatkowe środki zabezpieczające, aby zapewnić odpowiedni poziom ochrony danych przekazywanych do USA. Więcej informacji na temat przetwarzania Pani/Pana danych osobowych, w tym o przysługujących Pani/Panu prawach, jest dostępnych w Polityce prywatności.";
  }

  formatCurrency(
    amount: string,
    currencyCode: CurrencyCode,
    quantity?: number
  ) {
    return `${quantity ? `${quantity} x ` : ""}${(
      parseFloat(amount) / (quantity || 1)
    ).toFixed(2)} ${currencyMap[currencyCode] || ""}`;
  }

  getInputBaseColor() {
    return color2.ZABKA;
  }

  getEmptyReceiptContent() {
    return null;
  }

  // getOnboarding(navigation: any) {
  //   return <OnboardingZabka navigation={navigation} />;
  // }

  getFeatureFlags() {
    return {
      ...defaultFeatureFlags,
      taxEnabled: true,
      hideDraftProducts: true,
      productsTaxEnabled: false,
      isFirstData: true,
      isCompanyEnabled: true,
      isAddressEnabled: true,
      aifiFooterText: false,
      isGroupShopping: false,
      isOrderDetailQrCodeEnabled: false,
      isStripeV2: false,
    };
  }

  getSupportEmail() {
    return "on@zabka.pl";
  }

  getTelephone() {
    return "+48 61 856 37 00";
  }

  getAddress() {
    return "Żabka Polska sp. z o.o.,\n ul. Stanisława Matyi 8,\n 61-586 Poznań";
  }

  getOpenHours() {
    return "Jesteśmy dostępni\n od poniedziałku do niedzieli\n od 6:00 do 23:00";
  }

  getAboutUsLink() {
    return "https://www.zabka.pl/o-zabce";
  }

  getTouLink() {
    return "https://zabkapolskasa.sharepoint.com/:w:/s/AiFi2/Ef4IIgHPyz5PsO69k6R25H8BLj82erdG4oSUXtmSG22ZLQ";
  }

  getCircleLogo() {
    return <LogoZabka />;
  }

  getDefaultFontFamily() {
    return "TTCommons-Regular";
  }

  getPrimaryFont(): string {
    return this.getDefaultFontFamily();
  }

  getPrimaryFontLight(): string {
    return this.getDefaultFontFamily();
  }

  getSecondaryFont(): string {
    return this.getDefaultFontFamily();
  }

  getSecondaryFontBold(): string {
    return this.getDefaultFontFamily();
  }

  formatDateTime(dateTime: string) {
    const date = dayjs(dateTime);
    const formatDate = "DD.MM.YYYY";
    const formatTime = "HH:mm";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }

  getAPIEndpoint() {
    return "https://oasis-api.22-5.client.aifi.io";
  }

  getStripePublicKey() {
    return "pk_test_3rIBRoC1Al1XjGTholI5xINI00q6QPB0s1";
  }
}

const Singleton = new ZabkaFlavor();
export default Singleton;
