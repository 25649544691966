import { ITranslation } from './types'

export const ja: ITranslation = {
  addCardScreen: {
    headerTitle: 'カードの追加',
    stripeSetupIntentError: {
      header: 'Error $$',
      body: '何か問題が発生しました。別のカードをお試しください。 詳細: $$',
    },
  },
  button: {
    addCard: 'カードの追加',
    completeRegistration: '登録完了',
    confirm: '確認',
    continue: '次へ',
    getStarted: '開始',
    goToPayment: '支払い方法',
    login: 'ログイン',
    removeCard: 'カードの削除',
    signup: 'サインアップ',
    back: '戻る',
    skip: 'スキップ',
    error: 'エラー',
    payment: '支払いの再試行',
    ok: 'OK',
    yes: 'はい',
    no: 'いいえ',
  },
  contactUsScreen: {
    address: '住所',
    email: 'メールアドレス',
    headerTitle: 'お問い合せ',
    phone: '電話番号',
    dataProtectionEmail: 'データの削除',
    dataProtectionTitle:
      '個人データの削除をリクエストする場合には、次のメールアドレスにご連絡ください: ',
  },
  api: {
    E_FORCED_LOGOUT: '接続がタイムアウトしてログアウトされました。',
  },
  errors: {
    error: 'エラー',
    // E_CONFLICT: 'User already exists',
    E_NO_INTERNET: 'インターネット接続がありません。',
    E_CUSTOMER_NOT_FOUND: 'アカウントがみつかりません。',
    E_CUSTOMER_INVALID_CREDENTIALS: '無効なメールアドレスかパスワードです。',
    E_FORBIDDEN: 'アクセスが禁止されています。',
    E_NOT_FOUND: 'レコードがみつかりません。',
    E_ORDER_NOT_FOUND: '注文が存在しません。',
    E_PAYMENT_ERROR: 'ペイメントのサービスに接続する際に問題が発生しました。',
    E_STORE_NOT_FOUND: '店舗が見つかりません。',
    E_UNAUTHORIZED: 'アクセスが認証できません。',
    E_CUSTOMER_PAYMENT_METHOD_NOT_FOUND: '顧客は支払い方法を設定していません。',
    E_INVALID_BODY:
      '全てのフィールドを埋めてください。もしくは、フィールドが無効なフォーマットになっています。',
    E_ORDER_CUSTOMER_RELATION: 'その注文はお客様のものではありません。',
    E_RESET_CODE_ALREADY_GENERATED:
      'このメールアドレスのリセットコードが既に送信されています。後ほどお試しください。',
    E_RESET_CODE_INVALID: 'リセットコートが無効です。',
    E_RESET_CODE_NOT_FOUND: 'リセットコードが見つからないか期限切れです。',
    E_SHOPIFY_DUPLICATE_EMAIL: 'メールアドレスは既に使われています。',
    E_SHOPIFY_DUPLICATE_PHONE: '電話番号はすでに使われています。',
    E_SHOPIFY_INVALID_ORDER_ID: '注文が存在しません。',
    E_SHOPIFY_CUSTOMER_NOT_FOUND: 'お客様が見つかりません。',
    E_CUSTOMER_NO_PAYMENT_METHOD: 'お客様は支払い方法を設定していません。',
    E_CUSTOMER_UNSETTLED_TRANSACTIONS:
      'あなたの直前の処理は失敗しました。サポートにお問い合せください。',
    E_CUSTOMER_CARD_EXPIRED: 'お客様のカードは期限切れです。',
    SOMETHING_WRONG: '何かエラーが発生しています。後ほどお試しください。',
  },
  forgotPasswordScreen: {
    title: 'パスワードを忘れた方',
    wrongCodeErrorMessage: '正しくないコードです。',
    wrongEmailErrorMessage: '誤ったメールアドレスです。',
    codeErrorMessage: 'この項目は必須です。',
    emailErrorMessage: 'この項目は必須です。',
    passwordErrorMessage: 'この項目は必須です。',
    shortPasswordErrorMessage: 'パスワードの長さは最低8文字必要です。',
    alreadyHaveCode: '既にコードが存在します。',
  },
  form: {
    email: 'メールアドレス',
    emailPlaceholder: 'email@example.com',
    firstName: '名前（名）',
    firstNamePlaceholder: '太郎',
    lastName: '苗字（姓）',
    lastNamePlaceholder: '山田',
    newPassword: '新しいパスワード',
    password: 'パスワード',
    passwordPlaceholder: '８文字以上',
    resetCode: 'リセットコード',
    resetCodePlaceholder: '12345',
    productName: '製品名',
    productQuantity: '数量',
    labelPasswordShow: '表示',
    labelPasswordHide: '隠す',
    taxId: '納税者番号 （任意）',
    taxIdPlaceholder: '1234567890',
    companyName: '会社名（任意）',
    companyNamePlaceholder: '○○株式会社',
    phone: '電話番号（国番号+81から）',
    phonePlaceholder: '+819012345678',
    address: '住所',
    addressPlaceholder: '〒xxx-xx ○○県○○市○○１丁目２−１',
    addressError: '住所は必須です。',
    firstNameErrorMessage: '名前は必須です。',
    lastNameErrorMessage: '苗字は必須です。',
    emailErrorMessage: 'メールアドレスは必須です。',
    invalidEmailErrorMessage: '有効なメールアドレスではありません。',
    passwordErrorMessage: 'パスワードは必須です。',
    shortPasswordErrorMessage: 'パスワードは8文字以上の長さ必要です。',
    taxIdErrorMessage: 'これは有効な納税者番号ではありません。',
    taxIdEmptyErrorMessage: '納税者番号は必須です。',
    companyNameEmptyErrorMessage: '会社名は必須です。',
    phoneEmptyErrorMessage: '電話番号は必須です。',
    phoneInvalidErrorMessage: '電話番号は最低10桁必要です。',
  },
  loginScreen: {
    forgotPassword: 'パスワードを忘れた方',
    subtitle: 'メールアドレスとパスワードでログイン',
    title: 'Welcome back',
  },
  homeScreen: {
    cardInvalidSubtitle: '店舗へのアクセスを可能にするためにアカウント設定を完了してください。',
    cardValidTitle: 'スキャンして入店',
    partyText: 'ご来店人数:',
    greeting: 'こんにちは',
    errorUnpaid: '店舗へのアクセスを復旧するためにはこれまでの支払いを全て完了する必要があります。',
    errorUnpaidButton: '未払いのレシートを確認する',
    errorExpired: 'あなたのカードは全て期限切れです。新しいカードを登録してください。',
    errorExpiredButton: 'カードの追加',
    errorNoCard: 'クレジットカードが登録されていません。新しいカードを登録してください。',
    errorNoCardButton: 'カードの追加',
    errorUnknown: 'Unknown error',
  },
  menu: {
    access: 'アクセス',
    payment: '支払い',
    receipts: 'レシート',
    settings: 'お問い合せ',
  },
  onboardingScreen: {
    subtitle1: 'アプリのコードを使って入店...',
    title1: 'スキャンして入店',
    subtitle2: 'コードをスキャンした後みなさんを先に入店させて、その後ご自身がご入店ください。',
    title2: 'お二人以上での来店ですか？',
    subtitle3: '棚から取ったものは全て自動的にカートに追加されます。',
    title3: 'そして普通にお買い物するだけです。',
    subtitle4:
      'お買い物完了したらそのままお店を出てください。後ほどご購入のレシートを受け取れます。',
    title4: '準備はいいですか？ではお買い物をお楽しみください。',
  },
  registerLoginScreen: {
    register: '新規登録',
    login: 'ログイン',
  },
  paymentScreen: {
    card: {
      subtitle: 'これがお店で支払うために登録されている現在の支払い方法です。',
    },
    endsWith: '* * * * $$',
    noCard: {
      subtitle:
        '設定されている支払い方法がありません。下のボタンをタップしてカードを登録してください。',
    },
    removeCard: {
      cancel: 'キャンセル',
      delete: '削除',
      subtitle: '本当にカードを削除していいですか？',
      title: 'カードの削除',
    },
    title: '支払い方法',
    addCreditCard: {
      cardView: {
        labels: {
          expiry: 'MONTH/YEAR',
        },
      },
      labels: {
        name: 'カード名義',
        number: 'カード番号',
        expiry: '有効期限',
        cvc: 'セキュリティコード',
        postalCode: '郵便番号',
      },
      placeholders: {
        name: '氏名',
        number: '1234 5678 1234 5678',
        expiry: 'MM/YY',
        cvc: 'CVC',
        postalCode: '34567',
      },
    },
  },
  receiptDetailScreen: {
    headerTitle: '支払い明細',
    quantity: '数量: $$',
    subtotal: '小計',
    support: {
      body: '詳細を入力...\n\n---\n取引 ID: $$',
      contactCta: '間違っている場合にはご指摘ください。',
      subject: 'サポートのリクエスト',
    },
    tax: '税',
    taxCRV: 'CRV',
    total: '計',
    title: 'レシート',
    processTime: '処理時刻',
    shoppingTime: '購入時刻',
    emptyMessage:
      'お買い物ありがとうございます。今回、ご請求はありません。次回のご来店をお待ちしております！',
    problem: '問題があった場合は、レシートを編集して送ってください。内容を確認させていただきます。',
    date: '日付',
    address: 'お買い物有難うございました。',
    noItems: '何も購入されていません。',
    paymentStatus: '支払い状況',
    draftProducts:
      '現在あなたのお買い物を処理しています。レシートはもうすぐ見られるようになります。',
    orderId: '注文ID',
    feedbackSuccessTip: 'フィードバックありがとうございます。!',
    remindLaterTip: '有難うございます。ご都合の良い時間にまたご連絡します。',
    feedbackModalTitle: 'このお買い物体験をどの程度友人に勧めますか？',
    feedbackModalTip: '0～10の段階で得点を付けてください。',
    submitFeedback: 'フィードバックを投稿する',
    remindLater: 'またあとでリマインダーを送る',
    dontAskAgain: 'もう二度とフィードバックをリクエストしない。',
    notLikely: '全くそう思わない',
    veryLikely: '非常にそう思う',
    retryPaymentSuccessInfo: 'お支払いに成功しました、ありがとうございます！',
    retryPaymentFailureInfo: 'お支払いに失敗しました。しばらくしてからもう一度お試しください。',
  },
  receiptDetailComplain: {
    title: 'ユーザーフィードバック',
    send: '送信',
    feedbackTitle: 'レスポンス',
    feedbackMessage: 'レシートの修正を送信いただき有難うございます。できるだけ早く調査いたします。',
    addItem: '商品をレシートへ追加',
    reportProblem: '問題の報告',
    submitReview: 'レビューの送信',
    confirmTitle: '送信されたレシート',
    confirmMessage:
      'レシートの送信は完了しました。できるだけ早くご連絡いたします。元のレシートの金額以上に請求することはありません。システムの改善にご協力いただき有難うございます。',
    confirmButton: '完了',
    addMissingItem: '不足している項目の追加',
    addMissingItemMessage: 'メッセージの追加（任意）',
  },
  receiptAddItem: {
    title: '商品の追加',
  },
  receiptsScreen: {
    receipts: 'レシート',
    detail: '$$ 個の項目',
    detailItems: {
      one: '個',
      other: '個',
    },
    headerTitle: 'レシート',
    noReceiptsMessage: 'まだレシートはありません。\n新しいお買い物体験をしてみましょう。',
    processing1: '処理中',
    processing2: '取引',
    label: {
      contested: 'レビュー中',
      draft: 'ドラフト',
      empty: '空欄',
      paid: '支払い済',
      pending: 'ドラフト',
      refunded: '返金済',
      reviewed: '修正済',
      unknown: '不明',
      unpaid: '未払い',
    },
    message: {
      contested: 'Contested message',
      draft: 'Draft message',
      empty: 'Empty message',
      paid: 'Paid message',
      pending: 'Pending message',
      refunded: 'Refunded message',
      reviewd: 'Reviewed message',
      unpaid: 'Unpaid message',
    },
    resetAll: 'すべてをリセットする',
    for: 'for',
    minutes: '分',
    hours: '時間',
    days: '日々',
    stillWorkingOnIt: '現在まだ処理中です。',
  },
  settingsScreen: {
    headerTitle: '設置',
    items: {
      about: '本サービスに関して',
      contact: '',
      faq: 'よくある質問',
      faq2: 'この店舗はどのような仕組みになっているか？',
      logout: 'ログアウト',
      reportProblem: '問題を報告する',
      termsOfUse: '利用規約',
      storybook: 'ストーリーブック',
      disableNotification: '通知を無効にする',
    },
    security: 'セキュリティ',
    aboutUs: '本サービスに関して',
    myCards: '自分のカード',
    more: 'さらに詳しく',
    developer: '開発者',
    taxId: '納税者番号:',
    companyName: '会社:',
    address: '住所:',
  },
  signupScreen: {
    termsPrompt: '登録完了することで以下のものに同意したものとします：',
    termsPromptLink: '利用規約',
    title: 'サインアップ',
    privacyPolicy: 'プライバリーポリシー',
    privacyPolicyPrefix: 'さらに以下を確認ください： ',
  },
  slideModal: {
    close: '閉じる',
    searchPlaceholder: 'アイテムの検索',
  },
  notificationPermission: {
    title: '___RETAILER___ は、通知を送信したいと思っています',
    text: '自動店舗の機能に関する通知を ___RETAILER___ から受け取る。',
  },
}
